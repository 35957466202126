// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inscripcion-js": () => import("../src/pages/inscripcion.js" /* webpackChunkName: "component---src-pages-inscripcion-js" */),
  "component---src-pages-inscriptos-js": () => import("../src/pages/inscriptos.js" /* webpackChunkName: "component---src-pages-inscriptos-js" */),
  "component---src-pages-mapa-js": () => import("../src/pages/mapa.js" /* webpackChunkName: "component---src-pages-mapa-js" */),
  "component---src-pages-programa-js": () => import("../src/pages/programa.js" /* webpackChunkName: "component---src-pages-programa-js" */),
  "component---src-pages-registrarse-js": () => import("../src/pages/registrarse.js" /* webpackChunkName: "component---src-pages-registrarse-js" */)
}

